@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gray-50;
  }
}

.font-cormorant {
  font-family: 'Cormorant Infant', serif;
}